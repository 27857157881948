@import './Global.scss';

.Drop {
    position: relative;
    top: 0;
    left: 0;
    max-height: 100vh;
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;

    &-content {
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 300;
        bottom: 120px;
        color: #fff;
        width: 100%;
        // padding: 10px 5px;

        p {
            font-family: "Inter";
            font-weight: 200;
        }

        a {
            text-decoration: none;
        }
    }
}

#Drop-video-background {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

#Drop-image-background {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}