@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira:wght@300;500;700&display=swap");

.standard-btn {
    margin: 20px;
    background-color: #61dafb;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    color: #ffffff !important;
    font-size: 16px;
    font-weight: 500;
    min-width: 120px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #ffffff;
        color: #61dafb !important;
        cursor: pointer;
    }
}