@import './Global.scss';

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 64px;
    z-index: 1000;

    &-right {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 1rem;

        &-account {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            padding: 10px;
            width: 400px;
            // background-color: darken(#282c34, 3%);
            border-radius: 10px;

            &-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap: 0.5rem;
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;

                &-name {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }

        a {
            margin-left: 20px;
            text-decoration: none;
            color: #ffffff;
            font-size: 16px;
            font-weight: 500;
            transition: all 0.2s ease-in-out;

            &:hover {
                color: #61dafb;
            }
        }
    }
    @media screen and (max-width: 900px){
        a {
            font-size: 0.5rem;
        }

        .navbar-right {
            display: none;
        }
    }
}