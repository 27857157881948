.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  display: flex;
  min-height: 100vh;

  &-overlay {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    top: 0;
    background-image: url('../images/overlay.webp');
    opacity: 0.82;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    backdrop-filter: blur(0px) grayscale(0.35) saturate(180%) contrast(100%) brightness(110%);
    z-index: 1;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(43, 43, 43);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}