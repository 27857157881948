.Landing {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #ffffff;
    z-index: 3;

    a {
        color: darken(#61dafb, 10%);
        text-decoration: none;

        &:hover {
            color: lighten(#61dafb, 5%);
        }
    }

    &-logo {
        height: 40vmin;
        pointer-events: none;
        user-select: none;
    }

}