@import '../../styles/Global.scss';
.mintGlitchBtn {
    width: 380px;
    height: 86px;
    font-size: 36px;
    font-family: 'Inter', sans-serif;
    background-color: rgba(0, 0, 0, .52);
    border: none;
    color: #fff;
    letter-spacing: 3px;
    line-height: 88px;
    box-shadow: 2px 0px 1px darken(#61dafb, 45%);
    outline: transparent;
    position: relative;
    margin: 1rem;
    transition: all 0.2s ease-in-out;
    -webkit-box-reflect:below 1px linear-gradient(transparent, rgba(0, 0, 0, 0.65));

    &::after {
        --slice-0: inset(50% 50% 50% 50%);
        --slice-1: inset(80% -6px 0 0);
        --slice-2: inset(50% -6px 30% 0);
        --slice-3: inset(10% -6px 85% 0);
        --slice-4: inset(40% -6px 43% 0);
        --slice-5: inset(80% -6px 5% 0);
        
        content: 'MINT';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
        text-shadow: -3px -3px 0px #ffffff, 3px 3px 0px #00a8f6;
        clip-path: var(--slice-0);

        animation: glitch 1s infinite 0.5s linear;
        // animation: 1s glitch;
        animation-timing-function: steps(2, end);
    }

    &:hover {
        cursor: pointer;
        box-shadow: 2px 0px 1px darken(#61dafb, 25%);
        &::after {
            animation: glitch 1s infinite;
            // animation: 1s glitch;
            animation-timing-function: steps(2, end);
        }
    }
        
}

@keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }
    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }
    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }
    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }
    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }
    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }
    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }
    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }
    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }
    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }
    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }